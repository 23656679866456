import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'
import { WppIconDone, WppIconDragAndDrop, WppTooltip, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import React, { useEffect } from 'react'

import useRfiQuestionContext from 'hooks/useRfiQuestionContext'

import styles from './Item.module.scss'

export interface Props {
  dragOverlay?: boolean
  color?: string
  disabled?: boolean
  disableQuestion?: boolean
  dragging?: boolean
  handle?: boolean
  handleProps?: any
  height?: number
  index?: number
  fadeIn?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  style?: React.CSSProperties
  transition?: string | null
  wrapperStyle?: React.CSSProperties
  value: React.ReactNode
  id: string
  alreadyAnswered?: boolean
  onRemove?(): void
  renderItem?(args: {
    dragOverlay: boolean
    dragging: boolean
    sorting: boolean
    index: number | undefined
    fadeIn: boolean
    listeners: DraggableSyntheticListeners
    ref: React.Ref<HTMLElement>
    style: React.CSSProperties | undefined
    transform: Props['transform']
    transition: Props['transition']
    value: Props['value']
    id: Props['id']
  }): React.ReactElement
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        disableQuestion,
        fadeIn,
        handle,
        index,
        listeners,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        id,
        wrapperStyle,
        alreadyAnswered,
      },
      ref,
    ) => {
      const { rfiQuestionId, setRfiQuestionId, inDragMode } = useRfiQuestionContext()

      useEffect(() => {
        if (!dragOverlay) {
          return
        }
        if (!disabled && !disableQuestion) {
          document.body.style.cursor = 'grabbing'
        }

        return () => {
          document.body.style.cursor = ''
        }
      }, [dragOverlay, disabled, disableQuestion])

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
          id,
        })
      ) : (
        <div>
          <li
            className={clsx(
              styles.Wrapper,
              fadeIn && styles.fadeIn,
              sorting && styles.sorting,
              dragOverlay && styles.dragOverlay,
              rfiQuestionId === id ? 'bg-[#E0EBFF] rounded-lg' : undefined,
              'flex items-center',
            )}
            style={
              {
                ...wrapperStyle,
                transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
                '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                '--index': index,
                '--color': rfiQuestionId === id ? 'blue' : color,
              } as React.CSSProperties
            }
            ref={ref}
          >
            {inDragMode && (
              <button
                style={style}
                data-cypress="draggable-item"
                {...(!handle ? listeners : undefined)}
                tabIndex={!handle ? 0 : undefined}
              >
                <WppIconDragAndDrop color="gray" />
              </button>
            )}
            {alreadyAnswered ? (
              <WppTooltip text="For this question, an answer has already been proposed">
                <WppIconDone width={16} height={16} />
              </WppTooltip>
            ) : null}
            <div
              className={clsx(
                styles.Item,
                dragging && styles.dragging,
                handle && styles.withHandle,
                dragOverlay && styles.dragOverlay,
                (disabled || disableQuestion) && styles.disabled,
                color && styles.color,
                rfiQuestionId === id ? 'bg-[#E0EBFF]' : undefined,
              )}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (!disabled) {
                  setRfiQuestionId(id)
                }
              }}
            >
              {disableQuestion ? (
                <WppTooltip text="Please wait while the other questions are being processed">
                  <WppTypography className={clsx(rfiQuestionId === id ? 'text-[#0014CC]' : '')} type="s-body">
                    {value}
                  </WppTypography>
                </WppTooltip>
              ) : (
                <WppTypography className={clsx(rfiQuestionId === id ? 'text-[#0014CC]' : '')} type="s-body">
                  {value}
                </WppTypography>
              )}
            </div>
          </li>
        </div>
      )
    },
  ),
)
