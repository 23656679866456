import {
  WppFileUpload,
  WppInlineMessage,
  WppInput,
  WppListItem,
  WppSelect,
  WppTypography,
} from '@wppopen/components-library-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUploadFile } from 'api/mutations/pdfs/useUploadFile'
import { useCreateRfi } from 'api/mutations/rfis/useCreateRfi'
import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { EMPTY_PROJECT, ProjectState } from 'app/App'
import { queryClient } from 'app/Root'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import { UserCollaboration } from 'components/users/UserCollaboration'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import RfiSummaryError from 'pages/rfi-summary/RfiSummaryError'
import { RfiCreateProps } from 'types/rfis/rfi'
import { GetRfiReturn, Role } from 'types/users/userList'

import style from './NewProjectPage.module.scss'

const UPLOAD_PROCESSING_TIMER = 300

export default function NewProjectPage() {
  const [uploadTimer, setUploadTimer] = useState(UPLOAD_PROCESSING_TIMER)
  const [showError, setShowError] = useState(false)

  const saveProject = useCreateRfi()
  const { mutateAsync: uploadFile } = useUploadFile()
  const navigate = useNavigate()
  const { data: markets = [] } = useMarkets()
  const { data: agencies = [] } = useGetAgencies()
  const { data: regions = [] } = useRegions()
  const { data: pitchTypes = [] } = usePitchTypes()
  const { state, setState } = useProjectContext()

  const { data: taskStatus } = useTasksStatus({
    enabled: !!state.newTaskStarted?.id,
    refetchInterval: 3000,
    params: { taskId: state.newTaskStarted?.id || '' },
  })

  const toast = useToast()
  const isTaskRunning = state.newTaskStarted !== null && !taskStatus.completed

  useEffect(() => {
    if (taskStatus?.completed && !taskStatus.error) {
      // make sure our updates are handled asynchronously
      queryClient
        .invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
        .then(() => {
          toast.showToast({
            message: 'Project created successfully',
            type: 'success',
          })
          navigate(`/rfi-helper-tool/rfi-summary/${taskStatus?.resultObjectId || ''}`)
          handleUsersUpdated([])
        })
        .then(() => {
          setState(prev => ({
            ...prev,
            projectId: taskStatus?.resultObjectId || null,
            newTaskStarted: null,
            projectFiles: null,
            newProject: EMPTY_PROJECT,
          }))
        })
    }
    if (taskStatus?.error) {
      setState(prev => ({
        ...prev,
        newTaskStarted: null,
      }))
      setShowError(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus?.completed])

  // stop request if request takes longer than 5 mins
  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isTaskRunning && uploadTimer === 0) {
      queryClient.cancelQueries({ queryKey: [ApiQueryKeys.TASKS_STATUS] })
      setState({
        newProject: EMPTY_PROJECT,
        newTaskStarted: null,
        projectFiles: null,
        projects: state.projects || [],
        isFetchingProjects: false,
        isLoadingProjects: false,
      })
      setShowError(true)
    }
    if (isTaskRunning && uploadTimer > 0) {
      intervalId = setInterval(() => {
        setUploadTimer(prev => --prev)
      }, 1000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [uploadTimer, isTaskRunning, setState, state.projects])

  const updateState = (
    key: keyof RfiCreateProps,
    value: string | string[] | { member: string; role: keyof typeof Role }[],
  ) => {
    setState(
      prev =>
        ({
          ...prev,
          newProject: {
            ...prev.newProject,
            [key]: value,
          },
        }) as ProjectState,
    )
  }

  const handleFileUpload = async (projectId: string, uploadedFiles: File[]) => {
    try {
      const formData = new FormData()
      const files = uploadedFiles.map(file => new Blob([file], { type: 'application/pdf' }))
      files.forEach((file, index) => {
        formData.append('file', file, state.projectFiles?.[index].name)
      })
      uploadFile({ projectId, formData }).then(result => {
        setState(prev => ({
          ...prev,
          newTaskStarted: result.data,
          isCreatingProject: false,
        }))
      })
    } catch (e) {
      toast.showToast({
        type: 'error',
        message: 'Failed to upload file',
      })
    }
  }

  const handleFileUploadChange = (e: CustomEvent) => {
    setState(prev => ({ ...prev, projectFiles: e.detail.value, isCreatingProject: true }))

    saveProject
      .mutateAsync({
        ...state.newProject!,
        activeStatus: 1,
        agencies: state.newProject.agencies.map(agency => ({ id: agency })) as { id: string }[],
        projectMembers: state.newProject.projectMembers,
      })
      .then(result => {
        handleFileUpload(result.data.id, e.detail.value)
      })
      .catch(() => {
        toast.showToast({
          type: 'error',
          message: 'Failed to save project',
        })
        setState(prev => ({
          ...prev,
          newTaskStarted: null,
          isCreatingProject: false,
        }))
      })
  }

  const onRetryNewProject = () => {
    setShowError(false)
    setUploadTimer(UPLOAD_PROCESSING_TIMER)
    handleUsersUpdated([])
  }

  const handleUsersUpdated = (updatedUsers: GetRfiReturn[]) => updateState('projectMembers', updatedUsers)

  const uploadDisabled =
    !state.newProject?.client ||
    !state.newProject?.projectName ||
    !state.newProject?.pitchTypeId ||
    !state.newProject.agencies.length ||
    (state.newProject?.marketIds?.length || 0) < 1 ||
    state.isCreatingProject

  const disabledInputsCondition = state.isCreatingProject || isTaskRunning

  return (
    <>
      <WppTypography type="2xl-heading" className={style.newProjectTitle}>
        New Project
      </WppTypography>
      <div className="flex flex-col gap-3 bg-[#FFFFFF] px-[15px] pb-[15px] rounded-lg">
        {showError && <RfiSummaryError onRetry={onRetryNewProject} />}
        {!showError && (
          <>
            <div className="mt-4 flex flex-col gap-3 bg-[#FFFFFF]">
              <WppTypography type="xl-heading">Information</WppTypography>
              <div className="flex flex-row gap-3">
                <div className="flex-1">
                  <WppInput
                    value={state.newProject?.projectName}
                    disabled={disabledInputsCondition || undefined}
                    onWppChange={e => updateState('projectName', e.detail.value || '')}
                    required
                    labelConfig={{
                      text: 'Project Name',
                    }}
                  />
                </div>
                <div className="flex-1">
                  <WppSelect
                    disabled={disabledInputsCondition || undefined}
                    value={state.newProject?.pitchTypeId}
                    onWppChange={e => updateState('pitchTypeId', e.detail.value || '')}
                    required
                    labelConfig={{
                      text: 'Pitch Type',
                    }}
                  >
                    {pitchTypes
                      .toSorted((a, b) => a.typeDescription.localeCompare(b.typeDescription))
                      .map(pitchType => (
                        <WppListItem key={pitchType.id} value={pitchType.id}>
                          <p slot="label">{pitchType.typeDescription}</p>
                        </WppListItem>
                      ))}
                  </WppSelect>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex-1">
                  <WppInput
                    disabled={disabledInputsCondition || undefined}
                    value={state.newProject?.client || ''}
                    onWppChange={e => updateState('client', e.detail.value || '')}
                    required
                    labelConfig={{
                      text: 'Client Name',
                    }}
                  />
                </div>
                <div className="flex-1">
                  <WppSelect
                    required
                    disabled={disabledInputsCondition || undefined}
                    withSearch={true}
                    showSelectAllText={true}
                    type="multiple"
                    value={state.newProject?.marketIds || []}
                    onWppChange={e => updateState('marketIds', e.detail.value)}
                    labelConfig={{
                      text: 'Markets',
                    }}
                  >
                    {[...regions, ...markets].map(market => (
                      <WppListItem key={market.id} value={market.id}>
                        <p slot="label">{market.name}</p>
                      </WppListItem>
                    ))}
                  </WppSelect>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex-1">
                  <WppSelect
                    disabled={disabledInputsCondition || undefined}
                    value={state.newProject?.agencies}
                    required
                    labelConfig={{
                      text: 'Agency',
                    }}
                    placeholder="Select Agency"
                    withSearch={true}
                    onWppChange={e => updateState('agencies', e.detail.value)}
                    showSelectAllText={true}
                    type="multiple"
                  >
                    {agencies
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(agency => {
                        return (
                          <WppListItem key={agency.id} value={agency.id}>
                            <p slot="label">{agency.name}</p>
                          </WppListItem>
                        )
                      })}
                  </WppSelect>
                  <WppInlineMessage
                    className={style.inputInlineMessage}
                    message="Select the relevant agency (one or more) and its knowledge base for this project."
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col pt-4 bg-[#FFFFFF]">
              <UserCollaboration
                initUsers={[]}
                disabledInputsCondition={disabledInputsCondition}
                isTaskRunning={isTaskRunning}
                onUsersUpdated={handleUsersUpdated}
              />
            </div>

            <WppTypography type="xl-heading" className="mt-4">
              Upload files
            </WppTypography>
            <WppFileUpload
              name="rfiProjectPdf"
              disabled={uploadDisabled || isTaskRunning}
              acceptConfig={{ 'application/pdf': ['.pdf'] }}
              onWppChange={handleFileUploadChange}
              format="arrayBuffer"
              className={style.fileUpload}
            />
            {/* TODO: adjust it once the backed is ready to upload RFI */}
            {(state.isCreatingProject || isTaskRunning) && (
              <div className="h-[146px] rounded-lg w-full flex flex-row items-center justify-center p-[10px] bg-[#F8F9FB]">
                <LoaderProgressWithDescription taskStatus={taskStatus} />
              </div>
            )}
            {(state.isCreatingProject || state.newTaskStarted !== null) && !taskStatus.completed ? (
              <div className="flex flex-row items-center justify-start bg-[#ffffcc] rounded-lg border border-solid border-[#ffeb3b] gap-3 mt-4 px-5 py-4">
                <WppTypography type="s-body">
                  The project will be created once the file is uploaded and processed. Please wait...
                </WppTypography>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}
